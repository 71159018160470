<template>
  <b-row>
    <b-col>
      <b-aspect aspect="16:10">
        <b-img src="@/assets/luna.png" id="luna-art"/>
      </b-aspect>
      <div class="home-texts">
        <div id="welcome-text">
          Welcome to Luna!
        </div>
        <div>
          The server is currently {{ status }}.
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Home',
  data: function () {
    return {
      status: "cool and good"
    };
  },
  components: {}

}
</script>

<style scoped>
#luna-art {
  width: 100%;
  height: 100%;
}

#welcome-text {
  font-size: 2.5em;
  font-weight: bold;
}

.home-texts {
  text-align: center;
  width: 100%;
}
</style>