<template>
  <div id="app">

    <navbar/>

    <div id="content">
      <b-container fluid="xl">
        <router-view/>
      </b-container>
    </div>

  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
  components: {
    Navbar
  },
  created() {
  }
}
</script>

<style>

#content {
  margin-top: 70px;
  margin-bottom: 20px;
  color: white;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url("~@/assets/void.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

</style>