<template>
  <div id="navbar">
    <b-navbar toggleable="md" type="light" variant="light" fixed="top">

      <b-navbar-brand>
        <b><b-link to="/" id="brand-link">Luna</b-link></b>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>

      <b-collapse id="nav-text-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="https://ezotv.marcsello.com/">EZO.TV</b-nav-item>
          <b-nav-item href="https://kmlabz.com/">KMLabz</b-nav-item>
          <b-nav-item href="https://github.com/marcsello">GitHub</b-nav-item>
          <b-nav-item href="https://www.deviantart.com/marcsello">DeviantArt</b-nav-item>
          <b-nav-item href="https://soundcloud.com/marcsellohooves">Soundcloud</b-nav-item>
          <b-nav-item href="https://marcsello.com/">marcsello.com</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="about">About</b-nav-item>
        </b-navbar-nav>
      </b-collapse>

    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  components: {},
  methods: {},
  computed: {}
}
</script>

<style scoped>
nav.navbar {
  background-image: url("~@/assets/header_icon.png");
  background-repeat: no-repeat;
  background-size: auto 56px;
}

div.navbar-brand {
  padding-left: 100px;
}

#brand-link {
  color: black;
}
</style>